const siteOptionData = [
    {
      id: 1,
      siteName: "TOOFAN BRAND",
      image:
        "https://i.postimg.cc/BvH8MKMq/toofannetpromain.png",
      description: "খুব সহজে ডাওনলোড করতে পারবেন. নিচে দিয়া লিংকে ক্লিক করুন এবং পুরা ভিডিও দেখুন",
      siteLink: "https://youtu.be/EQH9ZRWDTsU?si=qsK8RDm_RoCpVTtX"
    }
  ];
  export default siteOptionData;
